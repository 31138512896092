import * as React from "react"
import { useEffect, useState } from 'react';
import { Link } from "gatsby"
import { Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import Layout from "../components/layout"
import Seo from "../components/seo"
import sessions from "../../data/sessions.json"
import * as helper from './../helper/utils.js';
import * as statusHelper from './../helper/status.js';
import * as calendar from './../helper/calendar.js';

let dayList = [
  '2023-06-05',
  '2023-06-06',
  '2023-06-07'
]

const Daily = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [hoverId, setHoverId] = useState();

  function toggleDetails(id) {
    if(showDetails) {
      if(id === hoverId) {
        setShowDetails(false);
        setHoverId(undefined);
      } else {
        setHoverId(id);  
        setShowDetails(true);
      }
    } else {
      setShowDetails(true);
      setHoverId(id);
    }    
  }

    async function wrapStatusSave(id, newStatus) {
        await statusHelper.save(id, newStatus);
        props.setLastChange(new Date());
      }

      let sessionList = [];

      if(props.displayList) {
        let merged = props.displayList.map(t1 => ({...t1, ...props.sessions.data.find(t2 => t2.id === t1.id)}))                                
        sessionList = merged.filter(x => x?.day?.id === dayList[props.day-1]);
        
        if(props.day === "4") {
          sessionList = merged.filter(x => x?.subconference?.id === '-txt-long-live-the-word-');
        }
        if(props.day === "Unknown") {
          sessionList = merged.filter(x => !x.begin);          
        }
        
        if(sessionList && sessionList.length > 0) 
        {
          sessionList = sessionList.sort(function(a,b){return a.begin > b.begin ? 1 : a.begin < b.begin ? -1 : 0 });
        }                
      }

      let dayHeader;
      if(props.day === "1") { dayHeader = 'Monday' };
      if(props.day === "2") { dayHeader = 'Tuesday' };
      if(props.day === "3") { dayHeader = 'Wednesday' };
      if(props.day === "Unknown") { dayHeader = 'Unknown' };

    return(
        <React.Fragment>            
            <h2>{dayHeader}</h2>
            <p></p>
            <Table responsive><tbody>
            { 
                sessionList
                ?          
                sessionList.map((item, key) => {
                    if(item) {
                    return (
                      <>
                        <tr key={key}>                                            
                            <td >
                              <Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 2)}>{helper.nameStatus(2)}</Button>&nbsp;
                              <Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 3)} >{helper.nameStatus(3)}</Button>&nbsp;
                              <Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 4)} >{helper.nameStatus(4)}</Button>
                            </td>  
                            <td>{item?.location?.label_en}</td>                          
                            <td>
                              <Badge bg={item.track?.id}>{helper?.trackName(item.track?.id)?.substring(0,2).toUpperCase()}</Badge>
                              {(item?.subconference?.id === "-txt-long-live-the-word-") ? <React.Fragment>&nbsp;<Badge bg='txt'>.txt</Badge></React.Fragment> : ''}
                              {(item?.subconference?.id === "tincon-x-re-publica-22") ? <React.Fragment>&nbsp;<Badge bg='TINCON'>TINCON</Badge></React.Fragment> : ''}
                            </td>
                            <td >{helper.scheduleTime(item?.begin, item?.end)}</td>
                            <td>{item?.duration}</td>
                            <td onClick={() => toggleDetails(item.id)}><Link to={`/session?id=${item.id}`} state={{ id: item.id}}>{item?.title?.substring(0,50)}</Link>                            
                            </td>
                            </tr>
                            {(showDetails && item.id === hoverId) ? <tr><td colSpan={6} onClick={() => toggleDetails(item.id)}>
                              <p></p>
                              {item.speakers.map((data, key) => {
                                return (
                                  <div key={key}>{data.name}</div>
                                );
                              })}    
  
                    <br></br>{item?.abstract}
                    </td></tr> : ''}
                    </>
                    );} else {
                      return(<React.Fragment></React.Fragment>)
                    }
                })
          :
          <React.Fragment>No choices yet</React.Fragment>
          }
          </tbody></Table>
        </React.Fragment>        
    )
}

const Schedule = (props) => {  
  const [displayList, setDisplayList] = useState();
  const [lastChange, setLastChange] = useState();
  

  function createICS() {    
    let test = calendar.createIcs(displayList.map(t1 => ({...t1, ...sessions.data.find(t2 => t2.id === t1.id)})));    
    const element = document.createElement("a");
    const file = new Blob([test], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "rp23.ics";
    document.body.appendChild(element);
    element.click();
  }

  useEffect(() => {        
    if (typeof window !== `undefined`) {
      let selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));      
        setDisplayList(selectedSessions?.filter(x => x.status === 1));    
      }
    
  }, [lastChange]);

  return (
  <Layout>
    <Seo title="Schedule" />
    <React.Fragment>
        <h1>Your <b>Schedule</b><sup><small>{displayList ? displayList.length : 0}</small></sup></h1>
        <Daily day="1" displayList={displayList} sessions={sessions} setLastChange={setLastChange}></Daily>
        <Daily day="2" displayList={displayList} sessions={sessions} setLastChange={setLastChange}></Daily>
        <Daily day="3" displayList={displayList} sessions={sessions} setLastChange={setLastChange}></Daily>
        <Daily day="4" displayList={displayList} sessions={sessions} setLastChange={setLastChange}></Daily>
        <Daily day="Unknown" displayList={displayList} sessions={sessions} setLastChange={setLastChange}></Daily>
    </React.Fragment>
    { displayList && displayList.length > 0 
        ?
        <Button onClick={createICS} variant="outline-secondary">Calendar (ICS)</Button>
        :
        <React.Fragment></React.Fragment>
        }
  </Layout>
)}

export default Schedule